/* globals window */
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {SchemaLink} from "apollo-link-schema";
import {makeExecutableSchema} from "graphql-tools";

const MSFO_API_IRL=process.env.REACT_APP_MSFO_API_URL;
const cache = new InMemoryCache();
let defaultDashboardItems = [];

export const getDashesAsync = () => {
    return fetch(MSFO_API_IRL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
            method: 'get',
            body: {
                type: 'dashboard3',
            }
        })
    });
};

export const getDashboardItems = () => {
    return JSON.parse(window.localStorage.getItem("dashboardItems")) ||
        defaultDashboardItems;
};


export const setDashboardItems = items =>
    window.localStorage.setItem("dashboardItems", JSON.stringify(items));


const nextId = () => {
    const dashboardItems = getDashboardItems();
    const currentId = dashboardItems.length > 0 ? dashboardItems.length : 1;
    window.localStorage.setItem("dashboardIdCounter", currentId + 1);
    return currentId.toString();
};

const toApolloItem = i => ({...i, __typename: "DashboardItem"});

export const updateDash = (dash, resultHandler) => {
    fetch(MSFO_API_IRL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
            method: 'update',
            body: {
              ...dash
            }
        })
    })
        .then(response => {
          // getDashes();
            if (resultHandler) {
                response.json().then(r => resultHandler(r.result) );
            }
        })
        .catch(console.log);
};

export const saveDashboard = (name, dash, resultHandler) => {
    fetch(MSFO_API_IRL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
            method: 'create',
            body: {
              type: 'dashboard3',
              data: {
                ...dash.data,
                name: name
              } 
            }
        })
    })
        .then(response => {
            console.log(response);
            if (resultHandler) {
              response.json().then(r => resultHandler(r.result) );
            }
        })
        .catch(console.log);
};

export const deleteDashboard = (id) => {
    fetch(MSFO_API_IRL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
            method: 'delete',
            body: {
              id: id
            }
        })
    })
        .then(response => {
            console.log(response);
        })
        .catch(console.log);
};

const typeDefs = `
  type DashboardItem {
    id: String!
    layout: String
    vizState: String
    extVizState: String
    extId: Int
    name: String
  }

  input DashboardItemInput {
    layout: String
    vizState: String
    extVizState: String
    extId: Int
    name: String
  }

  type Query {
    dashboardItems: [DashboardItem]
    dashboardItem(id: String!): DashboardItem
  }

  type Mutation {
    createDashboardItem(input: DashboardItemInput): DashboardItem
    updateDashboardItem(id: String!, input: DashboardItemInput): DashboardItem
    deleteDashboardItem(id: String!): DashboardItem
  }
`;
const schema = makeExecutableSchema({
    typeDefs,
    resolvers: {
        Query: {
            dashboardItems() {
                const dashboardItems = getDashboardItems();
                return dashboardItems.map(toApolloItem);
            },

            dashboardItem(_, {id}) {
                const dashboardItems = getDashboardItems();
                return toApolloItem(dashboardItems.find(i => i.id.toString() === id));
            }
        },
        Mutation: {
            createDashboardItem: (_, {input: {...item}}) => {
                const dashboardItems = getDashboardItems();
                item = {...item, id: nextId(), layout: JSON.stringify({})};
                dashboardItems.push(item);
                setDashboardItems(dashboardItems);
                return toApolloItem(item);
            },
            updateDashboardItem: (_, {id, input: {...item}}) => {
                const dashboardItems = getDashboardItems();
                item = Object.keys(item)
                    .filter(k => !!item[k])
                    .map(k => ({
                        [k]: item[k]
                    }))
                    .reduce((a, b) => ({...a, ...b}), {});
                const index = dashboardItems.findIndex(i => i.id.toString() === id);
                dashboardItems[index] = {...dashboardItems[index], ...item};
                setDashboardItems(dashboardItems);
                return toApolloItem(dashboardItems[index]);
            },
            deleteDashboardItem: (_, {id}) => {
                const dashboardItems = getDashboardItems();
                const index = dashboardItems.findIndex(i => i.id.toString() === id);
                const [removedItem] = dashboardItems.splice(index, 1);
                setDashboardItems(dashboardItems);
                return toApolloItem(removedItem);
            }
        }
    }
});
export default new ApolloClient({
    cache,
    link: new SchemaLink({
        schema
    })
});
