import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import { Layout } from "antd";
import {BrowserRouter} from 'react-router-dom';

import cubejs from "@cubejs-client/core";
import {CubeProvider} from "@cubejs-client/react";
import {ApolloProvider as ApolloHooksProvider} from "@apollo/react-hooks";
import {ApolloProvider} from "react-apollo";

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import client from "./graphql/client";

import { getDashesAsync } from './graphql/client';
import * as actionTypes from './store/actions'

import './index.scss';
import './assets/scss/style.scss';

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// let API_URL = "https://react-dashboard-demo.herokuapp.com";
//
// const cubejsApi = cubejs('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NzQwOTY4MTF9.wwiM9Ea3hBcJfyaWLDXYlLIbL7gZUwNeX3nBnIZ-9q4', {
//     apiUrl: `${API_URL}/cubejs-api/v1`
// });

const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_API_TOKEN, {
    apiUrl: process.env.REACT_APP_CUBEJS_API_URL
});


//DIRTY HACK
getDashesAsync().then( (r) => r.json().then( (j) => { 
  //store.dispatch({type: actionTypes.COLLAPSE_TOGGLE, menu: {id: 'dashboard', type: 'collapse'}}) ;
  store.dispatch({ type: actionTypes.DASHLIST_UPDATE, dashList: j.result  }) ;
  //if (j.result.length) {
  //  store.dispatch({ type: actionTypes.DASH_SET_CURRENT, dashboard: j.result[0]}) ;
  //}
}));


const app = (
    <Provider store={store}>
        <CubeProvider cubejsApi={cubejsApi}>
            <ApolloProvider client={client}>
                <ApolloHooksProvider client={client}>
                    <BrowserRouter basename={config.basename}>
                        <Layout style={{ height: "100%" }}>
                            <Layout.Content><App/></Layout.Content>
                        </Layout>
                    </BrowserRouter>
                </ApolloHooksProvider>
            </ApolloProvider>
        </CubeProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
