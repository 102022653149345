// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-5-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/resolve-url-loader/index.js??ref--6-oneOf-5-3!../node_modules/sass-loader/dist/cjs.js??ref--6-oneOf-5-4!antd/dist/antd.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=DM+Sans&display=swap&css);"]);
// Module
exports.push([module.id, ".ant-btn-primary[disabled] {\n  opacity: 0.4; }\n\n.ant-modal-mask {\n  z-index: 1000000 !important; }\n\n.ant-modal-wrap {\n  z-index: 1000000 !important; }\n\n.ant-select-dropdown, .ant-message {\n  z-index: 1000100 !important; }\n\n.ant-row-top button {\n  display: flex !important;\n  align-items: center !important;\n  line-height: 1 !important; }\n\n.ant-modal-header {\n  border-bottom: none;\n  padding: 40px 32px 0 32px; }\n\n.ant-modal-footer {\n  border-top: none;\n  padding: 0 32px 40px 32px;\n  text-align: left; }\n\n.ant-select {\n  color: #43436B; }\n\n.ant-select-dropdown-menu-item {\n  color: #43436B; }\n", ""]);
// Exports
module.exports = exports;
