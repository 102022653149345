import * as actions from './store/actions';

export default {
    items: [
        {
            id: 'support',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'collapse',
                    dashboards: true, //HARDCODED behavior 
                    classes: 'nav-item',
                    icon: 'feather icon-home',
                    isOpen: false,
                    children: []
                },
                {
                    id: 'Modeling',
                    title: 'Modeling',
                    type: 'item',
                    url: '/modeling',
                    classes: 'nav-item',
                    icon: 'fas fa-flask',
                    // action: actions.DASH_SET_CURRENT_EMPTY
                },

                // Demo
                {
                    id: 'DataPreparation',
                    title: 'Data Preparation',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'fas fa-blender',
                    children: [
                        {
                            id: 'DataFlow',
                            title: 'Dataflow',
                            type: 'item',
                            url: '/dataflow',
                            classes: 'nav-item',
                            icon: 'feather icon-wind',
                        },
                        {
                            id: 'DataSet',
                            title: 'Dataset',
                            type: 'item',
                            url: '/dataset',
                            classes: 'nav-item',
                            icon: 'feather icon-package',
                        },
                        {
                            id: 'Notebook',
                            title: 'Notebook',
                            type: 'item',
                            url: '/notebook',
                            classes: 'nav-item',
                            icon: 'feather icon-book',
                        },
                        {
                            id: 'DataSource',
                            title: 'Datasource',
                            type: 'item',
                            url: '/datasource',
                            classes: 'nav-item',
                            icon: 'feather icon-compass',
                        },
                    ]
                },
                {
                    id: 'KGraph',
                    title: 'KGraph',
                    type: 'item',
                    url: '/k-graph',
                    classes: 'nav-item',
                    icon: 'feather icon-package',
                    // action: actions.DASH_SET_CURRENT_EMPTY
                },
                {
                    id: 'DataTool',
                    title: 'Data Tool',
                    type: 'item',
                    url: '/data-tool',
                    classes: 'nav-item',
                    icon: 'feather icon-crop',
                },

                {
                    id: 'Charts',
                    title: 'Charts',
                    type: 'collapse',
                    classes: 'nav-item',
                    icon: 'feather icon-box',
                    children: [
                        // {
                        //     id: 'High Risk Charts',
                        //     title: 'High Risk Charts',
                        //     type: 'item',
                        //     url: '/risk-charts',
                        //     classes: 'nav-item',
                        //     icon: 'feather icon-box',
                        // },
                        {
                            id: 'AddChart',
                            title: 'Add new Chart',
                            type: 'item',
                            url: '/charts/add',
                            classes: 'nav-item',
                            icon: 'feather icon-plus',
                        }
                    ]
                },
                // {
                //     id: 'Charts Lab',
                //     title: 'Charts Lab',
                //     type: 'item',
                //     url: 'https://futurenaut.cognive.com/login/',
                //     classes: 'nav-item',
                //     icon: 'feather icon-box',
                //     external: true
                // },
                {
                    id: 'Clients',
                    title: 'Clients',
                    type: 'collapse',
                    icon: 'feather icon-users',
                    children: [
                        {
                            id: 'Matching',
                            title: 'Matching',
                            type: 'item',
                            url: '/coincidence-monitoring',
                        },
                        {
                            id: 'Monitoring',
                            title: 'Monitoring',
                            type: 'item',
                            url: '/monitoring',
                        },
                        {
                            id: 'Entity repository',
                            title: 'Entity repository',
                            type: 'item',
                            url: '/entity-repository',
                        },
                        {
                            id: 'Cases',
                            title: 'Cases',
                            type: 'item',
                            url: '/case-management',
                        },
                    ]
                },
                {
                    id: 'Administration',
                    title: 'Administration',
                    type: 'collapse',
                    icon: 'feather icon-settings',
                    children: [
                        {
                            id: 'UploadJournal',
                            title: 'Upload Journal',
                            type: 'item',
                            url: '/upload-journal',
                        },
                    ]
                },
                //                {
                //                    id: 'ml',
                //                    title: 'ML',
                //                    type: 'collapse',
                //                    icon: 'feather icon-codepen',
                //                    children: [
                //                        {
                //                            id: 'Correlation',
                //                            title: 'Correlation',
                //                            type: 'item',
                //                            url: '/correlation',
                //                        },
                //                        {
                //                            id: 'Clustering',
                //                            title: 'Clustering',
                //                            type: 'item',
                //                            url: '/clustering',
                //                        },
                //                        {
                //                            id: 'Dynamic KDI',
                //                            title: 'Dynamic KDI',
                //                            type: 'item',
                //                            url: '/dynamic-kdi',
                //                        },
                //                    ]
                //                },
                {
                    id: 'chart-maps',
                    title: 'Charts & Maps',
                    type: 'collapse',
                    icon: 'feather icon-codepen',
                    children: [
                        {
                            id: 'charts',
                            title: 'Charts',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'apex-chart',
                                    title: 'Apex Chart',
                                    type: 'item',
                                    url: '/charts/apex-chart'
                                },
                                {
                                    id: 'chart-js',
                                    title: 'Chart JS',
                                    type: 'item',
                                    url: '/charts/chart-js'
                                },
                                {
                                    id: 'high-chart',
                                    title: 'High Chart',
                                    type: 'item',
                                    url: '/charts/high-chart'
                                },
                                {
                                    id: 're-chart',
                                    title: 'Re-Chart',
                                    type: 'item',
                                    url: '/charts/re-chart'
                                },
                                {
                                    id: 'peity',
                                    title: 'Peity',
                                    type: 'item',
                                    url: '/charts/peity'
                                }
                            ]
                        },
                        {
                            id: 'maps',
                            title: 'Maps',
                            type: 'collapse',
                            children: [
                                {
                                    id: 'google',
                                    title: 'Google',
                                    type: 'item',
                                    url: '/maps/google-map'
                                },
                                {
                                    id: 'vector',
                                    title: 'Vector',
                                    type: 'item',
                                    url: '/maps/vector'
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
