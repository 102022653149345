import React, {Component, Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
            const RouteComponent = route.cached ? CacheRoute : Route

            return (route.component) ? (
                <RouteComponent
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )}/>
            ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <CacheSwitch>
                            {menu}
                            <Route path="/" component={AdminLayout}/>
                        </CacheSwitch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
