import * as actionTypes from './actions';
import config from './../config';
import navigation from '../menu-items.js';


const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
  dashList: [],
  navigation: navigation,
  currentDashboard: { data: { dashboard: [] } },
  exploredItem: {},
  monitoringDetails: {}
};

const updateDashList = (dashList, dash) => {

  if (dash.id === undefined) {
    console.error('Can\'t update list with nonpersistent dashboard');
    return dashList;
  }

  let others = dashList.filter((d) => d.id.toString() !== dash.id.toString())
  return [...others, dash]
}

const generateNavItems = (dashList) => {

  let children = dashList.map((d) => {
    return {
      id: d.data.name + d.id,
      title: d.data.name,
      type: 'item',
      dashboardId: d.id,
      url: '/dashboard/' + d.id,
    }
  });
  return children
}

const updateNav = (navigation, dashList) => {
  //DIRTY HACK
  let newnav = { ...navigation, items: [navigation.items[0]] }
  newnav.items[0].children = navigation.items[0].children
    .map(c => {
      if (c.dashboards === true) {
        return {
          ...c,
          children: generateNavItems(dashList)
        }
      }
      return c;
    })
  return newnav;
}

const reducer = (state = initialState, action) => {
  let trigger = [];
  let open = [];

  switch (action.type) {
    case actionTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: !state.collapseMenu
      };
    case actionTypes.COLLAPSE_TOGGLE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }

        if (triggerIndex === -1) {
          open = [...open, action.menu.id];
          trigger = [...trigger, action.menu.id];
        }
      } else {
        open = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
        trigger = (triggerIndex === -1) ? [action.menu.id] : [];
        open = (triggerIndex === -1) ? [action.menu.id] : [];
      }

      return {
        ...state,
        isOpen: open,
        isTrigger: trigger
      };
    case actionTypes.NAV_CONTENT_LEAVE:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case actionTypes.NAV_COLLAPSE_LEAVE:
      if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter(item => item !== action.menu.id);
          trigger = trigger.filter(item => item !== action.menu.id);
        }
        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        };
      }
      return { ...state };
    case actionTypes.FULL_SCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen
      };
    case actionTypes.FULL_SCREEN_EXIT:
      return {
        ...state,
        isFullScreen: false
      };
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout
      };
    case actionTypes.CHANGE_SUB_LAYOUT:
      return {
        ...state,
        subLayout: action.subLayout
      };
    case actionTypes.LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
        headerBackColor: initialState.headerBackColor
      };
    case actionTypes.NAV_BACK_COLOR:
      return {
        ...state,
        layoutType: (state.layoutType === 'menu-light') ? 'menu-dark' : state.layoutType
      };
    case actionTypes.HEADER_BACK_COLOR:
      return {
        ...state,
        headerBackColor: action.headerBackColor
      };
    case actionTypes.RTL_LAYOUT:
      return {
        ...state,
        rtlLayout: !state.rtlLayout
      };
    case actionTypes.NAV_FIXED_LAYOUT:
      return {
        ...state,
        navFixedLayout: !state.navFixedLayout
      };
    case actionTypes.HEADER_FIXED_LAYOUT:
      return {
        ...state,
        headerFixedLayout: !state.headerFixedLayout,
        headerBackColor: (!state.headerFixedLayout && initialState.headerBackColor === 'header-default') ? 'header-blue' : state.headerBackColor,
      };
    case actionTypes.BOX_LAYOUT:
      return {
        ...state,
        boxLayout: !state.boxLayout
      };
    case actionTypes.RESET:
      return {
        ...state,
        layout: initialState.layout,
        subLayout: initialState.subLayout,
        collapseMenu: initialState.collapseMenu,
        layoutType: initialState.layoutType,
        headerBackColor: initialState.headerBackColor,
        rtlLayout: initialState.rtlLayout,
        navFixedLayout: initialState.navFixedLayout,
        headerFixedLayout: initialState.headerFixedLayout,
        boxLayout: initialState.boxLayout
      };
    case actionTypes.DASHLIST_UPDATE:

      return {
        ...state,
        dashList: action.dashList,
        navigation: updateNav(state.navigation, action.dashList)
      }

    case actionTypes.DASHLIST_UPDATE_ITEM:

      let newDashList = updateDashList(state.dashList, action.dash);

      return {
        ...state,
        dashList: newDashList,
        navigation: updateNav(state.navigation, newDashList)
      }

    case actionTypes.DASHLIST_DELETE_ITEM:

      let newDashList2 = state.dashList
        .filter((d) => d.id.toString() !== action.dashId.toString())

      return {
        ...state,
        dashList: newDashList2,
        navigation: updateNav(state.navigation, newDashList2)
      }

    case actionTypes.DASH_UPDATE_ITEM:

      let item = { ...action.item }

      if (action.item.id === undefined) {
        console.log('generating new id');
        let newId = 0;
        if (state.currentDashboard && state.currentDashboard.data.dashboard.length) {
          newId = Math.max(...state.currentDashboard.data.dashboard.map(itm => itm.id)) + 1;
        }
        item.id = newId;
      }

      let items = state.currentDashboard.data.dashboard
        .filter((itm) => itm.id !== item.id)

      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          data: {
            ...state.currentDashboard.data,
            dashboard: [...items, item]
          }
        }
      }
    case actionTypes.DASH_ADD_ITEM:
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          data: {
            ...state.currentDashboard.data,
            dashboard: [
              ...state.currentDashboard.data.dashboard,
              action.item
            ]
          }
        }
      }
    case actionTypes.DASH_SET_CURRENT:
      return {
        ...state,
        currentDashboard: { ...action.dashboard }
      }

    case actionTypes.DASH_SET_CURRENT_BY_ID:
      return {
        ...state,
        currentDashboard: { ...state.dashList.find(d => d.id.toString() === action.dashId.toString()) },
        exploredItem: {}
      }

    case actionTypes.DASH_SET_CURRENT_EMPTY:
      return {
        ...state,
        currentDashboard: { data: { dashboard: [] } },
        exploredItem: {}
      }

    case actionTypes.DASH_SET_EXPLORED_ITEM:
      return {
        ...state,
        exploredItem: { ...action.exploredItem }
      }

    case actionTypes.DASH_REMOVE_ITEM:

      let items2 = state.currentDashboard.data.dashboard
        .filter((itm) => itm.id.toString() !== action.itemId.toString())

      console.log(items2)

      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          data: {
            ...state.currentDashboard.data,
            dashboard: items2
          }
        }
      }

    case actionTypes.MONITORING_DETAILS_UPDATE:
      return {
        ...state,
        monitoringDetails: action.details
      }

    default:
      return state;
  }
};

export default reducer;
